import { colors } from './colors';

interface TypographyProps {
  bodyLarge: React.CSSProperties;
  bodyMedium: React.CSSProperties;
  bodySmall: React.CSSProperties;
  bodyTiny: React.CSSProperties;

  headingLarge: React.CSSProperties;
  headingMedium: React.CSSProperties;
  headingSmall: React.CSSProperties;

  headerPopUp: React.CSSProperties;
  descriptiveSubtext: React.CSSProperties;
  mainBodyText: React.CSSProperties;
  buttonText: React.CSSProperties;
  smallTitle: React.CSSProperties;
  firstHeader: React.CSSProperties;
  secondHeader: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends TypographyProps {}
  interface TypographyVariantsOptions extends TypographyProps {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends TypographyProps {
    body1: false;
    body2: false;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    bodyTiny: true;
    headingLarge: true;
    headingMedium: true;
    headingSmall: true;

    headerPopUp: true;
    descriptiveSubtext: true;
    mainBodyText: true;
    buttonText: true;
    smallTitle: true;
    firstHeader: true;
    secondHeader: true;
  }
}

const header = {
  size1: {
    fontSize: '32px',
    lineHeight: '48px',
  },
  size2: {
    fontSize: '24px',
    lineHeight: '36px',
  },
  size3: {
    fontSize: '18px',
    lineHeight: '27px',
  },
};

const body = {
  size1: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  size2: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  size3: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  size4: {
    fontSize: '8px',
    lineHeight: '12px',
  },
};

// Exact values has to be discussed with UX/Design team
export const typography = {
  fontFamily: [
    'Open Sans',
    'system-ui',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Ubuntu',
    'Cantarell',
    'Noto Sans',
  ].join(','),
  htmlFontSize: 16,
  // font styles
  headerPopUp: {
    fontSize: header.size3.fontSize,
    lineHeight: header.size3.lineHeight,
    fontWeight: 700,
    color: colors.black[400],
  },
  descriptiveSubtext: {
    fontSize: body.size2.fontSize,
    lineHeight: body.size2.lineHeight,
    fontWeight: 400,
    color: colors.grey[500],
  },
  mainBodyText: {
    fontSize: body.size2.fontSize,
    lineHeight: body.size2.lineHeight,
    fontWeight: 400,
    color: colors.black[200],
  },
  buttonText: {
    fontSize: body.size4.fontSize,
    lineHeight: body.size4.lineHeight,
    fontWeight: 700,
    color: colors.grey[400],
  },
  smallTitle: {
    fontSize: body.size1.fontSize,
    lineHeight: body.size1.lineHeight,
    fontWeight: 700,
    color: colors.black[400],
  },
  firstHeader: {
    fontSize: body.size2.fontSize,
    lineHeight: body.size2.lineHeight,
    fontWeight: 700,
    color: colors.black[500],
  },
  secondHeader: {
    fontSize: body.size2.fontSize,
    lineHeight: body.size2.lineHeight,
    fontWeight: 700,
    color: colors.black[100],
  },
  // Headings
  headingLarge: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  headingMedium: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
  headingSmall: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  // Body
  bodyLarge: {
    fontSize: '1.5rem',
    lineHeight: '1.25rem',
  },
  bodyMedium: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  bodySmall: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  bodyTiny: {
    fontSize: '0.5rem',
    lineHeight: '0.75rem',
  },
};

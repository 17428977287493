import { SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { Base } from './Base';
import { capitalizeFirstLetter } from 'utils';
import { Check, Info as InfoIcon, ExclamationMark, X } from '@phosphor-icons/react';
import { Button, LinearProgress } from 'components';
import { t } from '@lingui/macro';
import { colors } from 'styles/theme/colors';
import { useTimer } from 'hooks/useTimer';

type SnackbarAction = {
  id: string;
  label: string;
  onClick?: (id: string) => void;
};

export type SnackbarProps = {
  id: string | number;
  message: string | React.ReactNode;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info';
  actions?: SnackbarAction[];
  countdown?: string;
};

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      id,
      message,
      variant,
      actions = [{ id: 'dismiss', label: t`Dismiss`, onClick: null }],
      countdown = '',
    },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    // Variants of the default snackbar, customized
    // snackbars should be put in different files
    const styles = useMemo(() => {
      switch (variant) {
        case 'info':
          return {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          };
        case 'warning':
          return {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          };
        case 'success':
          return {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          };
        case 'error':
          return {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          };

        default:
          return {
            backgroundColor: theme.palette.surface.main,
            color: theme.palette.text.primary,
          };
      }
    }, [variant]);

    const onEnd = () => {
      // click the button with id that matches the countdown text
      const countdownButton = actions.filter(action => action.id === countdown);
      if (countdownButton.length) {
        countdownButton[0].onClick?.(countdownButton[0].id);
      }
      handleDismiss();
    };

    return (
      <SnackbarContent ref={ref} role="alert">
        <Base baseSx={{ ...styles }}>
          <Row>
            <IconCircle>
              <Icon variant={variant} color={styles.backgroundColor} />
            </IconCircle>
            <Title>{capitalizeFirstLetter(variant)}</Title>
          </Row>
          <Content>
            <Message>{message}</Message>
            {actions?.length ? (
              <Buttons color={styles.color}>
                {actions.map(action => (
                  <Button
                    key={action.id}
                    onClick={() => (action.onClick ? action.onClick(action.id) : handleDismiss())}
                  >
                    {action.label}
                  </Button>
                ))}
              </Buttons>
            ) : null}
          </Content>
          {countdown && <CountdownBar onEnd={() => onEnd()} />}
        </Base>
      </SnackbarContent>
    );
  }
);

const CountdownBar = ({ onEnd }: { onEnd: () => void }) => {
  const timer = useTimer(15, 150);
  useEffect(() => {
    if (timer === 0) {
      onEnd();
    }
  }, [timer, onEnd]);
  return (
    <Countdown>
      <LinearProgress value={100 - (100 / 15) * timer} color="inherit" />
    </Countdown>
  );
};

type IconProps = {
  variant: string;
  color: string;
};

const Icon = ({ variant, color }: IconProps) => {
  switch (variant) {
    case 'success':
      return <Check size={18} color={color} />;
    case 'info':
      return <InfoIcon size={18} color={color} />;
    case 'warning':
      return <ExclamationMark size={18} color={color} />;
    case 'error':
      return <X size={18} color={color} />;
    default:
      return null;
  }
};

const Countdown = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  && .MuiLinearProgress-bar {
    background-color: ${colors.white[500]};
    opacity: 0.5;
  }
  && .MuiLinearProgress-root::before {
    background-color: transparent;
  }
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.white[500]};
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const Message = styled.span`
  font-size: 16px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  button {
    color: ${p => p.color};
    font-weight: 600 !important;
    text-decoration: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 36px;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

Snackbar.displayName = 'Snackbar';

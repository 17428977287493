import { Global, css, useTheme } from '@emotion/react';
import { colors } from './theme/colors';

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        main,
        body {
          background-color: #ffffff;
          font-size: ${theme.typography.mainBodyText.fontSize};
          line-height: ${theme.typography.mainBodyText.lineHeight};
          font-weight: ${theme.typography.mainBodyText.fontWeight};
          color: ${theme.typography.mainBodyText.color};
        }
        a {
          color: ${colors.black[500]};
          text-decoration: none;
          &:hover {
            text-decoration: underlined;
          }
        }
        ul {
          margin: 0;
          padding-left: 0;
        }
        /* Hide Sentry marketing in Dialog component */
        .powered-by {
          display: none;
        }
      `}
    />
  );
};

import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { components } from './components';

export const styledTheme = {
  palette,
  typography,
  spacing: {
    none: 0,
    xxs: 2,
    xs: 4,
    sNudge: 6,
    s: 8,
    mNudge: 10,
    m: 12,
    l: 16,
    xl: 20,
    xxl: 24,
    xxxl: 32,
  },
  breakpoints,
  components,
};

export const theme = createTheme({
  palette,
  typography,
  spacing: Object.values(styledTheme.spacing),
  breakpoints,
  components,
});

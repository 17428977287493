import { Box, Typography, styled, useTheme } from '@mui/material';
import { Stack } from 'components';
import { WarningCircle } from '@phosphor-icons/react';
import { FileTypePreview } from './FileTypePreview';
import { LinearProgressWithLabel } from 'components/progressBar';
import type { PreviewAreaProps } from '../types';

export const PreviewArea = ({ filesUploadData, layoutOptions, sx }: PreviewAreaProps) => {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <List
        component="ul"
        spacing={{ xs: 1, sm: 4 }}
        direction="column"
        useFlexGap
        flexWrap="wrap"
        {...layoutOptions}
        sx={sx}
      >
        {filesUploadData.data.map(({ id, file, progress }) => {
          return (
            <ListItem key={id}>
              <FileTypePreview file={file} size={24} />
              <Typography>{file.path}</Typography>
              {filesUploadData.status === 'error' ? (
                <WarningCircle
                  data-testid="icon-warning"
                  size={24}
                  color={theme.palette.error.main}
                />
              ) : (
                <Box sx={{ width: '200px' }}>
                  <LinearProgressWithLabel
                    sx={{ height: '24px', borderRadius: '4px' }}
                    value={progress || 0}
                  />
                </Box>
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const List = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

import { Typography, styled } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import type { ErrorOverlayProps } from './types';
import { t } from '@lingui/macro';

export const ErrorOverlay = ({ sx, error }: ErrorOverlayProps) => {
  return (
    <OverlayRoot data-testid="error-overlay">
      <Overlay sx={sx}>
        <Typography
          variant="bodyLarge"
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}
        >
          <WarningCircle size={28} />
          Error
        </Typography>
        <Typography variant="bodyMedium">{error?.message || t`Error loading image`}</Typography>
      </Overlay>
    </OverlayRoot>
  );
};

export const OverlayRoot = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: '0 0 0 0',
  borderColor: theme.palette.divider,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#E5E5E5', // TODO: Replace with palette when we have something from UX.
}));

const Overlay = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
}));

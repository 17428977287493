import { Box, Button, Modal as MuiModal, Typography, styled } from '@mui/material';
import { Minus, X } from '@phosphor-icons/react';
import { useStoreActions, useStoreState } from 'stores/global';
import { useMemo } from 'react';
import { ModalProps } from 'features/modalManager/types';

export const Modal = (props: ModalProps) => {
  const {
    modalSx,
    windowTitle,
    children,
    minimize = true,
    id,
    trigger,
    onClose,
    onMinimize,
    onOpen,
    onBackdropClick,
  } = props;

  const { activeId } = useStoreState(state => state.modalManager);
  const { add, remove, setActiveId, setModalListVisibility } = useStoreActions(
    actions => actions.modalManager
  );

  const styles = useMemo(() => {
    return {
      zIndex: activeId === id ? 1300 : -1,
    };
  }, [activeId]);

  const handleOpen = () => {
    setActiveId(id);
    add({ ...props });
    onOpen && onOpen();
  };

  const handleClose = (reason: string) => {
    setActiveId('');
    remove(id);
    onClose && onClose();

    if (reason === 'backdropClick') {
      onBackdropClick && onBackdropClick();
    }
  };

  const handleMinimize = () => {
    setActiveId('');
    setModalListVisibility(true);
    onMinimize && onMinimize();
  };

  return (
    <>
      <div>
        {trigger?.Component ? (
          <CustomButton onClick={handleOpen}>{trigger?.Component}</CustomButton>
        ) : (
          <Button
            onClick={handleOpen}
            variant={trigger?.variant ? trigger.variant : 'secondary'}
            disableRipple
          >
            {trigger?.label}
          </Button>
        )}
      </div>
      <MuiModal
        id={id}
        data-testid="modal-component"
        open={activeId === id}
        keepMounted={minimize}
        sx={styles}
        onClose={(_, reason: string) => handleClose(reason)}
      >
        <ModalWindow sx={modalSx?.window}>
          <WindowBar sx={modalSx?.windowBar}>
            <WindowTitle variant="bodyMedium" as="h3">
              {windowTitle}
            </WindowTitle>
            <WindowControls>
              {minimize && (
                <Minimize data-testid="minimize-modal" onClick={handleMinimize}>
                  <Minus size={12} />
                </Minimize>
              )}
              <Close data-testid="close-modal" onClick={() => handleClose('Close button')}>
                <X size={12} />
              </Close>
            </WindowControls>
          </WindowBar>
          {children}
        </ModalWindow>
      </MuiModal>
    </>
  );
};

const ModalWindow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  background: theme.palette.surface.light,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  overflow: 'hidden',
}));

const WindowBar = styled('div')(({ theme }) => ({
  background: theme.palette.surface.dark,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  paddingBlock: theme.spacing(6),
  paddingInline: theme.spacing(6),
  '& svg': { opacity: 0, transition: 'opacity 50ms' },
  '&:hover svg': { opacity: 1 },
}));

const WindowTitle = styled(Typography)({
  margin: 0,
});

const WindowControls = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const Circle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  overflow: 'hidden',
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  boxShadow: theme.shadows[1],
  transition: 'background 0.2s',
}));

const Close = styled(Circle)(({ theme }) => ({
  background: theme.palette.error.main,
}));

const Minimize = styled(Circle)(({ theme }) => ({
  background: theme.palette.success.main,
}));

const CustomButton = styled('button')({
  margin: 0,
  padding: 0,
  border: 0,
  background: 'transparent',
  display: 'flex',
  cursor: 'pointer',
});

import { colors } from 'styles/theme/colors';
import { format } from 'date-fns';

export const handleResponse = async (res: Response) => {
  const data = await res.json();

  return res.ok ? data : Promise.reject(data);
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLinkedColor = (linkedTo: string) => {
  switch (linkedTo) {
    case 'Styles':
      return colors.marineBlue[500];
    case 'Items':
      return colors.lavender[600];
    case 'Compliance':
      return colors.coral[600];
    case 'Sampling':
      return colors.palmGreen[500];
  }
};

export const getPriorityColor = (priority: string) => {
  switch (priority) {
    case 'Low':
      return colors.marineBlue[200];
    case 'Medium':
      return colors.marineBlue[300];
    case 'High':
      return colors.marineBlue[400];
    default:
      return colors.marineBlue[200];
  }
};
export const getStateColor = (state: string) => {
  switch (state) {
    case 'To Do':
      return colors.grey[600];
    case 'In Progress':
      return colors.warning[500];
    case 'Stuck':
      return colors.error[500];
    case 'Done':
      return colors.success[500];
  }
};
export const formatDateTime = (dateString: string | undefined, dateFormat: string) => {
  return dateString ? format(Date.parse(dateString), dateFormat) : '';
};

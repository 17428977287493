import { SnackbarContent } from 'notistack';
import { styled } from '@mui/material';
import type { BaseProps } from '../types';

export const Base = ({ children, baseSx }: BaseProps) => {
  return (
    <SnackbarContent role="alert">
      <Container data-testid="snackbar-container" sx={baseSx}>
        <Content>{children}</Content>
      </Container>
    </SnackbarContent>
  );
};

export const Container = styled('div')(({ theme }) => ({
  minWidth: 288,
  display: 'flex',
  position: 'relative',
  padding: theme.spacing(6),
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',
}));

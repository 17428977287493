import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { theme, styledTheme } from 'styles/theme/theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

export type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <EmotionThemeProvider theme={styledTheme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </EmotionThemeProvider>
  );
};

import { useState } from 'react';
import { Chip, type ChipProps } from './Chip';
import { styled } from '@mui/material';

export type ChipsListProps = {
  chips: ChipProps[];
  onDelete?: (chip: ChipProps) => void;
};

export const ChipsList = ({ chips, onDelete }: ChipsListProps) => {
  const [chipsData, setChipsData] = useState<readonly ChipProps[]>(chips || []);

  const handleDelete = (chipToDelete: ChipProps) => () => {
    setChipsData(prev => prev.filter(chip => chip.id !== chipToDelete.id));
    onDelete && onDelete(chipToDelete);
  };

  return (
    <List>
      {chipsData.map(chip => {
        return <Chip component="li" key={chip.id} {...chip} onDelete={handleDelete(chip)} />;
      })}
    </List>
  );
};

const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
  paddingBlock: theme.spacing(4),
}));
